"use client";

import { LanguageParams } from "@/lib/types/PageProps";
import { useTranslation } from "@/lib/i18n/client";
import { MenuEntry } from "@/lib/constants/menuEntries";
import GenericMenuItem from "../GenericMenuItem/GenericMenuItem";
import { authAtom } from "@/lib/jotai/auth/authStore";
import { useRouter } from "next/navigation";
import { useAtomValue, useSetAtom } from "jotai";
import { userLogOut } from "@/lib/utils/logout";

type DesktopNavigationProps = {
    menuItems: (MenuEntry[] | MenuEntry)[] | MenuEntry;
} & LanguageParams;

const DesktopNavigation = ({ lang, menuItems }: DesktopNavigationProps) => {
    const setAuth = useSetAtom(authAtom);
    const auth = useAtomValue(authAtom);
    const { t } = useTranslation(lang, "menu");
    const router = useRouter();

    if (Array.isArray(menuItems)) {
        menuItems.forEach((el) => {
            if ((el as MenuEntry).key === "Log out") {
                (el as MenuEntry).onClickHandler = () => {
                    userLogOut(setAuth, router, lang, auth);
                };
            }
            return el;
        });
    }

    return (
        <>
            {Array.isArray(menuItems) ? (
                menuItems
                    .reduce<MenuEntry[]>((p, c) => (Array.isArray(c) ? p.concat(c) : [...p, c]), [])
                    .map((e) => (
                        <GenericMenuItem
                            key={e.key + "-desktop"}
                            url={e.url}
                            itemIcon={e.menuIcon}
                            hasSubMenu={e.hasSubMenu}
                            onClickHandler={e?.onClickHandler}
                            subMenuItems={e.subMenuItems}
                            text={t(e.key)}
                            lang={lang}
                        />
                    ))
            ) : (
                <GenericMenuItem
                    key={menuItems.key + "-desktop"}
                    url={menuItems.url}
                    onClickHandler={menuItems?.onClickHandler}
                    itemIcon={menuItems.menuIcon}
                    text={t(menuItems.key)}
                    lang={lang}
                />
            )}
        </>
    );
};

export default DesktopNavigation;
