import { Urls, getUrl } from "@/lib/constants/urls";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { Trans } from "react-i18next";
import Link from "next/link";

const ConditionsMessage = ({ termsAndConditionsUrl = "" }) => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "general");

    const linkClasses = "underline cursor-pointer hover:no-underline hover:text-primary-300";

    const privacyPolicyUrl = getUrl(Urls.privacyPolicy.index, lang);
    return (
        <div className="leading-tight">
            <span className="text-sm text-gray-500">
                <Trans
                    i18nKey="acknowledgeTermsAndConditions"
                    t={t}
                    components={[
                        <Link
                            className={linkClasses}
                            key={"terms-and-conditions"}
                            href={termsAndConditionsUrl}
                            target="_blank"
                        />,
                    ]}
                />
            </span>
            <br />
            <span className="text-sm text-gray-500">
                <Trans
                    i18nKey="acknowledgePrivacyPolicy"
                    t={t}
                    components={[
                        <Link className={linkClasses} key={"privacy-policy"} href={privacyPolicyUrl} target="_blank" />,
                    ]}
                />
            </span>
        </div>
    );
};

export default ConditionsMessage;
