import { TFunction } from "i18next";
import * as Yup from "yup";
import { ValidationError } from "yup";
import "yup-phone-lite";

const stringNoNumbers = (t: TFunction<string, string>) => ({
    name: "noNumbersAllowed",
    message: t("no-numbers-allowed"),
    test: (value: string | undefined) => {
        value = value ?? "";
        if (/\d/.test(value)) {
            return false;
        }

        return true;
    }
});

export const getPasswordValidationSchema = () =>
    Yup.string()
        .trim()
        .test({
            test: (value) => {
                const errors = [];
                const password = value ?? "";
                if (password == "") errors.push("field_required");
                if (password.length < 7) errors.push("password_min_length");
                if (password.length > 20) errors.push("password_max_length");
                if (!/^.*[!#$%&’()*+,-./:;<=>?@[\]].*$/.test(password)) errors.push("password_special_character");
                if (!/^.*\d.*$/.test(password)) errors.push("password_number");
                if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(password)) errors.push("password_upper_lower_letter");

                if (errors.length > 0) {
                    throw new Yup.ValidationError({
                        errors: errors,
                        inner: [],
                        path: "password",
                        message: errors.toString(),
                        value: value,
                        name: "ValidationError",
                    } as unknown as ValidationError);
                }

                return true;
            },
        });

export const getGuestRegitrationFormValidationSchema = (t: TFunction<string, string>) =>
    Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .required(t("first-name-required") || "")
            .test(stringNoNumbers(t)),
        lastName: Yup.string()
            .trim()
            .required(t("last-name-required") || "")
            .test(stringNoNumbers(t)),
        email: Yup.string()
            .trim()
            .required(t("email-required") || "")
            .email(t("invalid-email") || ""),
        password: getPasswordValidationSchema(),
    });

export const getBecomeAHostFormValidationSchema = (t: TFunction<string, string>) =>
    Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .required(t("errors.field-required") || "")
            .min(2, t("errors.first-name-min-length", { min: 2 }))
            .test(stringNoNumbers(t)),
        lastName: Yup.string()
            .trim()
            .required(t("errors.field-required") || "")
            .min(2, t("errors.last-name-min-length"))
            .test(stringNoNumbers(t)),
        nameOfAccommodation: Yup.string()
            .trim()
            .required(t("errors.field-required") || ""),
        companyName: Yup.string()
            .trim()
            .required(t("errors.field-required") || ""),
        taxId: Yup.string()
            .trim()
            .required(t("errors.field-required") || ""),
        email: Yup.string()
            .trim()
            .required(t("errors.field-required") || "")
            .email(t("errors.invalid-email") || ""),
        city: Yup.string()
            .trim()
            .required(t("errors.field-required") || ""),
        country: Yup.string()
            .trim()
            .required(t("errors.field-required") || ""),
        phoneNumber: Yup.string()
            .trim()
            .required(t("errors.field-required") || ""),
        password: getPasswordValidationSchema(),
    });

export const getCheckoutGuestFormValidationSchema = (t: TFunction<string, string>) =>
    Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .required(t("form-validation.first-name-required") || "")
            .test(stringNoNumbers(t)),
        lastName: Yup.string()
            .trim()
            .required(t("form-validation.last-name-required") || "")
            .test(stringNoNumbers(t)),
        email: Yup.string()
            .trim()
            .required(t("form-validation.email-required") || ""),
        country: Yup.string()
            .trim()
            .required(t("form-validation.country-required") || ""),
        guestPhone: Yup.string()
            .trim()
            .required(t("form-validation.phone-number-required") || "")
            .phone(undefined, t("form-validation.phone-number-invalid") || ""),
        additionalGuests: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string()
                    .trim()
                    .required(t("form-validation.first-name-required") || ""),
                lastName: Yup.string()
                    .trim()
                    .required(t("form-validation.last-name-required") || ""),
            }),
        ),
    });

const checkoutBillingDefaultFieldsValidationSchema = () =>
    Yup.object().shape({
        street: Yup.string().trim(),
        number: Yup.string().trim(),
        additionalAddressLine: Yup.string().trim(),
        zipCode: Yup.string().trim(),
        city: Yup.string().trim(),
        country: Yup.string().trim(),
    });

export const getPrivateBillingFormValidationSchema = () => checkoutBillingDefaultFieldsValidationSchema();

export const getBusinessBillingFormValidationSchema = (t: TFunction<string, string>) => {
    const businessFields = Yup.object().shape({
        companyName: Yup.string()
            .trim()
            .required(t("form-validation.company-name-required") || ""),
        vatId: Yup.string().trim().required(t("form-validation.vat-id-required")),
    });
    const defaultFields = checkoutBillingDefaultFieldsValidationSchema();
    return defaultFields.concat(businessFields);
};
