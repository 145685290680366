"use client";
import { faShieldCheck, faShieldXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Text from "../Text";
import { useEffect, useState } from "react";

export type RuleProps = {
    satisfied: boolean;
    touched: boolean;
    text: string;
};

const Rule = ({ satisfied, touched, text }: RuleProps) => {
    const [color, setColor] = useState("text-gray-500");

    useEffect(() => {
        if (touched) {
            satisfied ? setColor("text-green-500") : setColor("text-red-500");
        }

    }, [satisfied, touched]);

    const renderIcon = (satisfied: boolean, touched: boolean) => {
        const iconClasses = "h-5 w-4";
        if (!touched)
        {
            return <FontAwesomeIcon icon={faShieldXmark} className={iconClasses} />;
        }
        return satisfied ? (
            <FontAwesomeIcon icon={faShieldCheck} className={iconClasses} />
        ) : (
            <FontAwesomeIcon icon={faShieldXmark} className={iconClasses} />
        );
    };

    return (
        <div className={`flex gap-2 ${color} mt-2`}>
            <span className="flex items-center text-base">{renderIcon(satisfied, touched)}</span>
            <Text as="p" className="text-sm">
                {text}
            </Text>
        </div>
    );
};



export default Rule;