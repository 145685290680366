"use client";

import { getGuestRegitrationFormValidationSchema } from "@/lib/constants/validationSchemas";
import { useTranslation } from "@/lib/i18n/client";
import { API } from "@/lib/services/authService";
import { RegistrationFormData } from "@/lib/services/registrationService";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { SCREEN_BREAKPOINTS } from "@/lib/constants/tailwind";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";
import Text from "@/lib/components/Text";
import Input from "@/lib/components/Input";
import { Field, Form, Formik } from "formik";
import Alert from "@/lib/components/Alert";
import PasswordRules from "@/lib/components/PasswordRules";
import Button from "../../../Button";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { getFieldStatus, showFieldError } from "@/lib/utils/general";
import { Urls, getUrl } from "@/lib/constants/urls";
import { useAtom, useSetAtom } from "jotai";
import { authAtom, isLoginModalShownAtom } from "@/lib/jotai/auth/authStore";
import GoogleLoginButton from "@/lib/components/Auth/GoogleLoginButton";
import ConditionsMessage from "@/lib/components/ConditionsMessage";
import { UserInfo } from "@/lib/jotai/auth/types";
import { getUserInfo } from "@/lib/hooks/useUserInfo";
import { checkoutFormsAtom } from "@/lib/jotai/checkout/checkoutStore";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";

type GuestRegistrationFormProps = {
    onGoToLoginButtonClick?: () => void;
    onGoogleRegistrationSuccess?: (userInfo: UserInfo | null, error: Error | null) => void;
    onSuccessRedirectUri?: string;
    isOnCheckout?: boolean;
};

export default function GuestRegistrationForm({
    onGoToLoginButtonClick,
    onGoogleRegistrationSuccess,
    onSuccessRedirectUri = "",
    isOnCheckout = false,
}: GuestRegistrationFormProps) {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "registration");

    const RegistrationFormSchema = getGuestRegitrationFormValidationSchema(t);
    const [registrationError, setRegistrationError] = useState<string | null>(null);
    const [width] = useTailwindBreakpoint();
    const openLoginModal = useSetAtom(isLoginModalShownAtom);
    const router = useRouter();
    const termsAndConditionsUrl = getUrl(Urls.termsAndConditionsGuests.index, lang);
    const [auth, setAuth] = useAtom(authAtom);
    const [, setCheckoutForms] = useAtom(checkoutFormsAtom);

    const initialValues: RegistrationFormData = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        language: lang,
    };

    const onSubmit = async (values: RegistrationFormData) => {
        try {
            await API.RegisterGuest(values);
            await getUserInfo(auth, setAuth, setCheckoutForms, router);
            GoogleTagManagerEvents.submitRegisterPageInfo(
                lang,
                isOnCheckout ? "checkout" : "sign_up_page",
                auth.isLoggedIn ? auth.userInfo._id : undefined,
            );
            if (onSuccessRedirectUri && onSuccessRedirectUri != "") {
                router.push(onSuccessRedirectUri);
            } else {
                location.reload();
            }
        } catch (err) {
            if (err instanceof Error) {
                if (err.message.includes("There is already an account with email")) {
                    setRegistrationError(t("email-already-exists"));
                }
            }
        }
    };

    const onWithGoogle = async (googleTokenId: string) => {
        try {
            const response = await API.GoogleLogin(googleTokenId);
            if (onGoogleRegistrationSuccess) {
                onGoogleRegistrationSuccess(response, null);
            } else {
                if (!auth.isLoggedIn) {
                    setAuth({
                        ...auth,
                        isLoggedIn: true,
                        userInfo: response,
                    });
                }
                GoogleTagManagerEvents.submitRegisterPageInfo(
                    lang,
                    isOnCheckout ? "checkout" : "sign_up_page",
                    auth.isLoggedIn ? auth.userInfo._id : response._id,
                    "Google",
                );
                if (onSuccessRedirectUri && onSuccessRedirectUri != "") {
                    router.push(onSuccessRedirectUri);
                } else {
                    router.push(getUrl(Urls.home.index, lang));
                }
            }
        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
                if (onGoogleRegistrationSuccess) {
                    onGoogleRegistrationSuccess(null, err);
                }
            }
        }
    };

    return (
        <div className="grow">
            <div className="mb-6 lg:mb-4">
                <Text as="h6" className="font-semibold text-md lg:text-xl">
                    {t("register")}
                </Text>
            </div>
            {/* Registration Buttons */}
            <div className="grid gap-4 grid-cols-1">
                <GoogleLoginButton
                    label={width <= SCREEN_BREAKPOINTS.sm ? t("google") : t("register-google")}
                    onClick={onWithGoogle}
                />
            </div>
            {/* Registration form */}
            <div className="flex flex-col gap-4">
                <div className="w-full font-bold text-sm md:text-lg text-center mt-6 md:mt-12">
                    {width <= SCREEN_BREAKPOINTS.sm ? t("or-with-your-email") : t("register-with-your-email")}
                </div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={RegistrationFormSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, dirty, isSubmitting }) => (
                        <Form className="flex flex-col">
                            <div className="grid grid-cols-1 mb-2">
                                {registrationError && <Alert messages={[registrationError]} />}
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2">
                                <Field
                                    useDefaultBorderStyles={false}
                                    as={Input}
                                    id="firstName"
                                    name="firstName"
                                    type={"text"}
                                    placeholder={t("first-name") as string}
                                    className={`w-full text-sm border border-gray-300  p-2 rounded-t-md lg:rounded-tl-md lg:rounded-tr-none `}
                                    status={getFieldStatus(!!touched.firstName, !!errors.firstName)}
                                    errorMessage={
                                        showFieldError(dirty, errors.firstName, touched.firstName) && errors.firstName
                                    }
                                />
                                <Field
                                    useDefaultBorderStyles={false}
                                    as={Input}
                                    id="lastName"
                                    name="lastName"
                                    type={"text"}
                                    placeholder={t("last-name") as string}
                                    className={`w-full text-sm border border-gray-300  p-2 lg:rounded-tl-none lg:rounded-tr-md`}
                                    status={getFieldStatus(!!touched.lastName, !!errors.lastName)}
                                    errorMessage={
                                        showFieldError(dirty, errors.lastName, touched.lastName) && errors.lastName
                                    }
                                />
                            </div>
                            <div>
                                <Field
                                    useDefaultBorderStyles={false}
                                    as={Input}
                                    id="email"
                                    name="email"
                                    type={"email"}
                                    placeholder={t("email") as string}
                                    className={`w-full text-sm border border-gray-300  p-2`}
                                    status={getFieldStatus(!!touched.email, !!errors.email)}
                                    errorMessage={showFieldError(dirty, errors.email, touched.email) && errors.email}
                                />
                            </div>
                            <div>
                                <Field
                                    useDefaultBorderStyles={false}
                                    as={Input}
                                    id="password"
                                    name="password"
                                    type={"password"}
                                    placeholder={t("password") as string}
                                    className={`w-full text-sm border border-gray-300  p-2 rounded-b-md`}
                                    status={getFieldStatus(!!touched.password, !!errors.password)}
                                />
                            </div>
                            {/* Password privacy and rules */}
                            <div className="flex flex-col mt-2">
                                <div className="md:block hidden w-full font-bold mt-4">
                                    {t("password-privacy-and-rules")}:
                                </div>
                                <div className="grid grid-cols-1 md:p-2 gap-2">
                                    <PasswordRules
                                        lang={lang}
                                        touched={!!values.password || (touched.password ?? false)}
                                        validationResult={errors.password ?? ""}
                                        submit={isSubmitting}
                                    />
                                </div>
                            </div>
                            {/* Agreements checkbox */}
                            <div className="flex flex-col mt-4 gap-2">
                                <ConditionsMessage termsAndConditionsUrl={termsAndConditionsUrl} />
                            </div>
                            <div className="flex flex-col">
                                <Button
                                    isLoading={isSubmitting}
                                    variant="orange"
                                    type="submit"
                                    size="custom"
                                    className="font-bold h-12 mt-4"
                                >
                                    {t("register")}
                                </Button>
                            </div>
                            <div className="text-sm text-left pt-2">
                                {t("have-account")}
                                {!onGoToLoginButtonClick ? (
                                    <span
                                        className="text-primary-500 max-sm:underline px-2 cursor-pointer hover:underline"
                                        onClick={() => {
                                            GoogleTagManagerEvents.clickOnGoToLogin(lang, "sign_up_page");
                                            openLoginModal(true);
                                        }}
                                    >
                                        {t("login")}
                                    </span>
                                ) : (
                                    <span
                                        className="text-primary-500 max-sm:underline px-2 cursor-pointer hover:underline"
                                        onClick={() => {
                                            GoogleTagManagerEvents.clickOnGoToLogin(lang, "checkout");
                                            openLoginModal(false);
                                            onGoToLoginButtonClick();
                                        }}
                                    >
                                        {t("login")}
                                    </span>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
