import { useEffect, useState } from "react";
import Rule from "../Rule";
import { useTranslation } from "@/lib/i18n/client";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";
import { SCREEN_SIZE } from "@/lib/constants/tailwind";
import { breakpointIsLessThan } from "@/lib/utils/tailwind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons";

export type PasswordRulesProps = {
    validationResult?: string;
    touched: boolean;
    lang: string;
    submit: boolean;
};

const PasswordRules = ({ validationResult, touched, lang, submit }: PasswordRulesProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, currentBreakpoint] = useTailwindBreakpoint();
    const { t } = useTranslation(lang, "registration");
    const DESKTOP_BREAKPOINT: SCREEN_SIZE = "xl";
    const [isMobile, setIsMobile] = useState(breakpointIsLessThan(currentBreakpoint, DESKTOP_BREAKPOINT));
    const [showList, setShowList] = useState(false);

    const toggleList = () => {
        setShowList(!showList);
    };

    useEffect(() => {
        setIsMobile(breakpointIsLessThan(currentBreakpoint, DESKTOP_BREAKPOINT));
        setShowList(!isMobile);
    }, [currentBreakpoint, isMobile]);

    useEffect(() => {
        if (submit && validationResult) {
            setShowList(true);
        }
    }, [submit, validationResult]);

    return (
        <div>
            <div className="mt-2 md:hidden">
                <FontAwesomeIcon icon={faShieldCheck} className="text-cyan-500 mr-2" />
                <button
                    type="button"
                    onClick={toggleList}
                    className="text-black-500 underline cursor-pointer font-bold"
                >
                    {t("show-password-security-information")}
                </button>
            </div>
            <div
                className={`${showList ? "h-auto" : "h-0"} overflow-hidden transition-max-height duration-500 ${
                    isMobile ? "px-6" : ""
                }`}
            >
                <Rule
                    touched={touched}
                    satisfied={
                        !validationResult?.includes("password_min_length") &&
                        !validationResult?.includes("password_max_length") &&
                        !validationResult?.includes("field_required")
                    }
                    text={t("rule-number-of-characters")}
                />
                <Rule
                    satisfied={!validationResult?.includes("password_special_character")}
                    text={t("rule-special-characters")}
                    touched={touched}
                />
                <Rule
                    satisfied={!validationResult?.includes("password_number")}
                    touched={touched}
                    text={t("rule-numbers")}
                />
                <Rule
                    satisfied={!validationResult?.includes("password_upper_lower_letter")}
                    touched={touched}
                    text={t("rule-case")}
                />
            </div>
        </div>
    );
};

export default PasswordRules;
